import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

export default class TestAddPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet titleTemplate="%s | Pathlab">
          <title>{`Test Add / Clinical Details`}</title>
          <meta
            name="description"
            content={`Request that a test be added to an existing request.`}
          />
        </Helmet>
        <section className="section">
          <div className="container content-border">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="content">
                  <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                    Test Add / Clinical Details
                  </h1>
                  <hr />
                  <p>
                    To request a test add or provide clinical details, fill in
                    the form below.
                  </p>
                  <h3>
                    Test adds are monitored Monday – Friday, 8am – 5pm for all
                    sites.
                  </h3>
                  <p>
                    <strong>
                      Outside of these hours, all URGENT HOSPITAL test add
                      requests require a hard copy to be sent to the laboratory.
                    </strong>
                  </p>
                  <div className="columns is-desktop">
                    <div className="column is-6-desktop">
                      <p>
                        If the form does not load, send a message to one of
                        these dedicated email addresses:
                      </p>
                      <ul>
                        <li>
                          Tauranga -{" "}
                          <a
                            href="mailto:plbop.testadd@pathlab.co.nz"
                            className="has-text-primary"
                          >
                            <strong>plbop.testadd@pathlab.co.nz</strong>
                          </a>
                        </li>
                        <li>
                          Waikato -{" "}
                          <a
                            href="mailto:plw.testadd@pathlab.co.nz"
                            className="has-text-primary"
                          >
                            <strong>plw.testadd@pathlab.co.nz</strong>
                          </a>
                        </li>
                        <li>
                          Rotorua -{" "}
                          <a
                            href="mailto:plr.testadd@pathlab.co.nz"
                            className="has-text-primary"
                          >
                            <strong>plr.testadd@pathlab.co.nz</strong>
                          </a>
                        </li>
                        <li>
                          Taupo -{" "}
                          <a
                            href="mailto:plt.testadd@pathlab.co.nz"
                            className="has-text-primary"
                          >
                            <strong>plt.testadd@pathlab.co.nz</strong>
                          </a>
                        </li>
                        <li>
                          Whakatane -{" "}
                          <a
                            href="mailto:plwhk.testadd@pathlab.co.nz"
                            className="has-text-primary"
                          >
                            <strong>plwhk.testadd@pathlab.co.nz</strong>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="column is-6-desktop">
                      <p>
                        The subject should read, 'Test Add Required or Providing
                        Clinical Details'. The body of the email must contain:
                      </p>
                      <ul>
                        <li>Patient NHI</li>
                        <li>Requester Name and Clinic</li>
                        <li>Date of the original request</li>
                        <li>The additional testing that is required</li>
                      </ul>
                    </div>
                  </div>
                  <hr />
                  <iframe
                    id="test-add-form"
                    title="Test Add Form"
                    src="https://forms.office.com/Pages/ResponsePage.aspx?id=AWoWqGyZBU2T4SubP7FHPuMFEfVfJNdMrPLc4msvdpFUQVg1MDhLMDBOR0tFM0VTM0JZSk4xN1BDVy4u&embed=true"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
